<template>
  <div class="w-full h-screen bg-[#2B2633] overflow-y-scroll">
    <div class="flex items-center justify-between px-3 py-4 bg-[#202126]">
      <div class="flex items-center gap-3">
        <div class="w-[2px] h-5 line" />
        <p class="text-lg">最新消息</p>
      </div>
      <router-link to="/"> <van-icon name="cross" size="24px" color="#909093" /></router-link>
    </div>
    <!-- <div class="relative flex items-center text-sm py-3">
      <div class="flex-1 text-center transition-all duration-300" :class="{ 'text-[#24B6E4]': msgType === 0 }"
        @click="changeType(0)">
        最新消息
      </div>
      <div class="flex-1 text-center transition-all duration-300" :class="{ 'text-[#24B6E4]': msgType === 1 }"
        @click="changeType(1)">
        我的讯息
      </div>
      <div class="w-1/2 absolute transform translate-y-1/2 bottom-0 transition-all duration-150"
        :style="`left: ${50 * msgType}%`">
        <div class="w-8 bg-[#24B6E4] rounded mx-auto" style="height: 3px" />
      </div>
    </div> -->
    <div class="flex items-center justify-between p-4 bg-main-100 border-b border-white border-opacity-10">
      <div class="flex items-center gap-2 text-xs">
        <div class="px-2 py-1 rounded border transition-all duration-200" :class="
          showType === 'all'
            ? ' border-[#24B6E4] text-[#24B6E4] bg-[#07232F]'
            : 'text-[#909093] bg-[#1C1316] border-[#1C1316]'
        " @click="showType = 'all'">
          显示全部
        </div>
        <div class="px-2 py-1 rounded border transition-all duration-200" :class="
          showType === 'unread'
            ? ' border-[#24B6E4] text-[#24B6E4] bg-[#07232F]'
            : 'text-[#909093] bg-[#1C1316] border-[#1C1316]'
        " @click="showType = 'unread'">
          显示未读
        </div>
      </div>
      <img @click="setAllRead" src="@/assets/icons/message/all-read.svg" />
    </div>
    <div class="p-4 bg-main-100 overflow-y-scroll" style="height: calc(100vh - 153px)">
      <!-- <TopMessageSwiper v-if="isTopList.length" :isTopList="isTopList" /> -->
      <MessageItem v-for="item of filterList" :key="item" :info="item" />
      <div v-if="!filterList.length && !isTopList.length"
        class="w-full py-20 flex flex-col items-center justify-center gap-3">
        <img src="@/assets/icons/no-data.svg" />
        <p class="text-sm text-[#909093]">暂无资料</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import MessageItem from '../components/Message/MessageItem.vue'
import TopMessageSwiper from '../components/Message/TopMessageSwiper.vue'
import { get_note_list, set_all_read } from '@/api/message'
import { computed, onMounted } from 'vue'
import { showConfirmDialog } from 'vant';
const msgType = ref(0)
const showType = ref('all')
const messageList = ref([])
const isTopList = ref([])
onMounted(async () => {
  await getMessageList()
})
const getMessageList = async () => {
  const res = await get_note_list({
    note_type: msgType.value + 1,
  })
  if (!res.error) {
    const isTopList = res.filter((e) => e.is_top).sort((a, b) => b.id - a.id)
    const msgList = res.filter((e) => !e.is_top).sort((a, b) => b.id - a.id)
    messageList.value = [...isTopList, ...msgList]
  }
}
const filterList = computed(() => {
  const showTypeList = messageList.value.filter((e) => !e.is_read)
  return showType.value === 'all' ? messageList.value : showTypeList
})
const changeType = async (type) => {
  msgType.value = type
  await getMessageList()
}
const setAllRead = async () => {
  showConfirmDialog({
    title: '提示',
    message: '是否要一键已读所有讯息'
  }).then(async () => {
    await set_all_read({ note_type: msgType.value + 1 })
    await getMessageList()
  })
}
</script>


<style lang="scss" scoped>
.line {
  background: linear-gradient(180deg, #FF2A6D, #FF2AAF);
}
</style>

